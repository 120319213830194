@use "../../react-components/styles/theme.scss";
@import 'shared';

:local(.ui) {
  @extend %default-font;

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  pointer-events: none;
}

body.vr-mode {
  :local(.ui) {
    pointer-events: auto;
  }
}

:local(.interstitial) {
  @extend %default-font;
  background-color: theme.$overlay-bg-color;
  color: theme.$overlay-text-color;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
  pointer-events: auto;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;

  div {
    @extend %action-button;
    width: 250px;
  }
}

:local(.avatar-editor) {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: theme.$background1-color;
  z-index: 11;
  pointer-events: auto;
}
