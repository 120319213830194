@use "../../react-components/styles/theme.scss";
@import 'shared';

:local(.avatar-editor) {
  padding: 2em;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: flex-start;
  color: theme.$text1-color;

  @media (max-width: 768px) {
    padding: 0.5em;
    flex-direction: column;
    align-items: center;
  }

  > .loader {
    position: absolute;
    left: calc(50% - 4.3em);
    top: calc(50% - 4.5em);
  }

  .info {
    text-align: center;
    width: 100%;
    color: theme.$text2-color;
    margin: 1em 0;

    p {
      margin: 0;
      margin-bottom: 0.5em;
      a {
        margin-right: 5px;
      }
    }

    a {
      color: theme.$link-color;
    }
  }

  .center {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    align-items: center;
    overflow: auto;
    @media (min-width: 768px) {
      padding-top: 2em;
    }
  }

  form {
    display: flex;
    flex-direction: column;

    > label {
      margin-top: 8px;
    }

    details {
      margin-bottom: 10px;
    }

    > .text-field-container {
      display: flex;
      align-items: center;
      label {
        margin-right: 5px;
      }
    }

    .file-input-row {
      display: flex;
      align-items: center;
      &.disabled{
        opacity: 0.5;
      }
      label {
        display: flex;
        align-items: center;
        margin: 5px 0;
        cursor: pointer;
      }
      input {
        display: none;
      }
      .img-box {
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
        border-radius: 8px;
        margin-right: 5px;
        background: theme.$tile-bg-color;
        overflow: hidden;
        color: theme.$tile-text-color;
        position: relative;
        svg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 20px;
          height: 20px;
        }
      }
      img {
        width: 100%;
      }
      a {
        margin-left: 10px;
        color: theme.$link-color;
      }
    }

    .text-field {
      @extend %input-field;
      font-size: 16px;
    }

    .textarea {
      @extend %input-field;
      font-size: 16px;
    }

    .select-container {
      position: relative;
      .arrow{
        position: absolute;
        right: 10px;
        bottom: 20px;
      }
    }

    .select-grid-container {
      .select-grid {
        display: flex;
        flex-wrap: wrap;
        margin-top: 5px;
        justify-content: space-between;
        max-width: 355px;
      }

      input {
        display: none;
      }

      .item {
        box-sizing: border-box;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: theme.$text2-color;
        text-align: center;
        cursor: pointer;
        margin-bottom: 5px;
        width: calc(1 / 3 * 100% - (1 - 1 / 3) * 10px);
        position: relative;

        &.selected {
          background: theme.$tile-bg-color;
        }

        &:hover {
          background: theme.$tile-bg-color-hover;
        }

        &:active {
          background: theme.$tile-bg-color-pressed;
        }

        &.custom{
          width: 100%;
          height: 40px;

          svg {
            margin-right: 8px;
            margin-top: -1px;
          }
        }
        img {
          position: absolute;
          top: 5px;
          left: 5px;
          width: calc(100% - 10px);
          height: calc(100% - 10px);
        }
      }
    }

    .select {
      @extend %input-field;
      font-size: 16px;
      height: 36px;
      -webkit-appearance: none;
      -moz-appearance: none;
    }


    .checkbox-container {
      display: flex;
      align-items: center;
      margin: 5px 0;

      .checkbox {
        cursor: pointer;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        width: 2em;
        height: 2em;
        border: 1px solid theme.$input-border-color;
        background-color: theme.$input-bg-color;
        border-radius: 6px;
        vertical-align: sub;
        margin: 0 0.7em;
        min-width: 2em;
        min-height: 2em;
      }
      .checkbox:checked {
        position: relative;
        &::after {
          content: ' ';
          position: absolute;
          background: theme.$active-color;
          top: 0.3em;
          left: 0.3em;
          right: 0.3em;
          bottom: 0.3em;
          border-radius: 0.3em;
        }
      }
    }

    .license {
      font-size: 8pt;
      color: theme.$text2-color;
      a {
        color: theme.$text2-color;
      }
    }

    hr {
      width: 100%;
      border: none;
      border-bottom: 1px solid theme.$border2-color;
    }

    a {
      color: theme.$text2-color;
    }
  }

  .preview {
    margin-left: 2em;
    height: 450px;
    max-width: 200px;
    @media (max-width: 768px) {
      margin-left: 0.5em;
    }
  }

  .form-submit {
    @extend %action-button;
    background: theme.$primary-color;
    margin: 0;
    margin-bottom: 0.5em;
  }
  .form-submit:disabled {
    background-color: theme.$disabled-bg-color;
  }

  .split {
    display: grid;
    grid-template: 100% / 55% 45%;
    align-items: center;
  }

  canvas {
    width: 100%;
    height: 100%;
  }

  .delete-avatar {
    margin-top: 20px;
    color: theme.$text2-color;
    a {
      cursor: pointer;
      text-decoration: underline;
      color: theme.$cancel-color;
    }
  }

  .close-button {
    cursor: pointer;
    color: theme.$text2-color;
    outline-style: none;
    background: none;
    border: none;

    margin-top: 16px;
    font-size: 1.6em;
    align-self: flex-start;

    @media (max-width: 768px) {
      padding-left: 0.5em;
    }
  }
}
