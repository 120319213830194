@import 'shared';

html, body {
  height: 100%;
}

#ui-root {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

body.vr-mode-stretch {
  a-scene {
    .a-canvas {
      width: 200% !important;
    }
  }
}

.a-enter-vr, .a-orientation-modal {
  display: none;
}

.grab-cursor {
  cursor: grab;
}

.no-cursor {
  cursor: none;
}

.webxr-realities, .webxr-sessions {
  @extend %unselectable
}

// HACK: Deals with a performance regression in Chrome 75 where, once
// enough elements are on the page, the Chrome "reader" functionality continually
// scanning the page on mouse drag kills performance.

a-entity {
  display: none;
}

.svg-icon {
  overflow: visible;
  display: inline-block;
  font-size: inherit;
  vertical-align: -0.125em;
  height: 1em;
}

// Dat GUI
.dg select {
  color: black;
}
.dg input {
  line-height: normal;
}
