@use "../styles/theme.scss";

:local(.near-phone-popover) {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
  padding: 10px 10px 0 10px;
  background-color: #111;
  border-radius: 5px;
  resize: both;
  overflow: auto;
  height: 440px;
  width: 370px;
  cursor: move;
  max-width: 100vw;
  max-height: 100vh;
  min-width: 200px;
  min-height: 300px;
  box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -8px;     

  > ul.action-sheet {
    position: absolute;
    bottom: 60px;
    width: calc(100% - 20px);
    line-height: 2;
    background-color: #fff;
    text-align: center;
    z-index: 1;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: none;
  }

  > ul.action-sheet li {
    padding: 5px;
    border-top: 1px solid #ddd;
    cursor: pointer;
  }

  > div.home button {
    white-space: normal;
    width: 100%;
  }

  > div.home {
    width: 100%;
    height: calc(100% - 60px);    
    background-image: linear-gradient(to top, #a8edea 0%, #fed6e3 100%);
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  > div.home > svg {
    position: absolute;
    z-index: 0;
    width: calc(100% - 20px);
    height: calc(100% - 70px);
    opacity: 0.1;
  }

  > div.home ul {
    display: flex;
	  flex-direction: row;
	  flex-wrap: wrap;
	  justify-content: center;
	  align-items: start;
	  align-content: center;
    z-index: 1;
    position: relative;
    margin: 20px 5px;

  }

  > div.home li {
    width: 65px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    align-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    margin-left: 3px;
    margin-right: 3px;
  }  

  > div.home li.open {
    width: 100%;
    padding: 20px;
    background: #fffc;
    color: #000;
    display: block;
    height: auto;
    text-align: center;
    margin: 0 20px;
    border-radius: 5px;
  }  

  > div.home li.open.group {
    background: #fff6;
    color: inherit;
    border-radius: 5px;
    padding-left: 0;
    padding-right: 0;
  }

  > div.home li.open.group::before {
    content: '';
    width: 100%;
    position: absolute;
  }

  > div.home li.group > ul {
    display: none;
  }

  > div.home li.open.group > ul {
    display: flex;
  }

  > div.home > ul > li > div,
  > div.home > ul > li > ul > li > div {
    font-size: 16px;
    height: 48px;
    align-items: center;
    display: flex;
  }

  > div.home > ul > li.open > div,
  > div.home > ul > li > ul > li.open > div {
    height: 80px;
  }

  > div.home > ul > li.open > div > img,
  > div.home > ul > li > ul > li.open > div > img {
    max-height: 72px;
    max-width: 72px;
  }

  > div.home > ul > li.open > span,
  > div.home > ul > li > ul > li.open > span {
    font-size: 16px;
  }

  > div.home > ul > li > p,
  > div.home > ul > li > ul > li > p {
    display: none;
    font-size: 13px;
    margin-top: 10px;
    text-align: justify;
  }

  > div.home > ul > li.open > p,
  > div.home > ul > li > ul > li.open > p {
    display: block;
  }

  > div.home > ul > li > div:last-child,
  > div.home > ul > li > ul > li > div:last-child {
    display: none;
    width: 100%;
    padding: 20px 0 10px 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  > div.home > ul > li.open > div:first-child,
  > div.home > ul > li ul > li.open > div:first-child {
    display: block;
  }

  > div.home > ul > li.open.group > div:first-child {
    display: none; 
  }

  > div.home > ul > li.open.group > span {
    font-size: 16px;
    font-weight: 500;
  }

  > div.home > ul > li.open > div:last-child,
  > div.home > ul > li > ul > li.open > div:last-child {
    display: grid;
    height: auto;
    gap: 10px;
  }

  > div.home > ul > li > div:last-child a:first-child button,
  > div.home > ul > li > ul > li > div:last-child a:first-child button {
    
  }

  > div.home > ul > li > div:last-child a:last-child button,
  > div.home > ul > li > ul > li > div:last-child a:last-child button {
    text-align: center;
    font-weight: normal;
    border-width: 1px;    
  }

  > div.home > ul > li > div img,
  > div.home > ul > li > ul > li > div img {
    border-radius: 50%;
    max-height: 48px;
    max-width: 48px;
    display: inline-block;
  }

  > div.home > ul > li.group > div span {
    background: #000;
    color: #eee;
    padding: 3px;
    border-radius: 40%;
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    border: 2px solid #fff;    
  }

  $repeat: 80;
  @for $i from 1 through $repeat{
    > div.home > ul > li.group:nth-child(#{$i}) > div span {
          background-color: rgb(random(255),random(255),random(255));            
    }
  }

  > div.home > ul > li.group > div span img {
    width: 18px;
    opacity: 0.8;
    margin-left: 1px;
  }

  > div.home > ul > li > span,
  > div.home > ul > li > ul > li > span {
    margin-top: 4px;
    font-size: 12px;
    text-align: center;
    max-width: 75px;
    text-overflow: ellipsis;
    overflow: hidden;
    min-height: 16px;
  }
  
  > div.content, > div.guestbook {
    width: 100%;
    height: calc(100% - 60px);
    display: none;
  } 

  div.guestbook {
    background-image: linear-gradient(to top, #a8edea 0%, #fed6e3 100%);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 16px;
  } 

  div.guestbook textarea {
    padding: 7px;
    max-height: 50%;
    width: 100%;
    height: 32px;
    min-height: 32px;
    color: #000;
  }

  div.guestbook button {
    width: 100%;
    padding: 6px;
    margin-top: 6px;
    margin-bottom: 12px;
    color: #000;
  }

  > div.actions {
    line-height: 60px;
  }

  > div.actions ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    font-size: 22px;
  }  

  > div.actions ul li {
    color: #999;    
    cursor: pointer;
    width: 60px;
    align-content: space-around;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 59px;
  }

  > div.actions ul li:hover {
    color: #fff;
  }

  > div.actions ul li svg {
    fill: #999;
    max-height: 45px;
  }

  > div.actions ul li:hover svg {
    fill: #fff
  }

  iframe {
    height: 100%;
    width: 100%;
    border: 2px solid #111;
    border-radius: 5px;
  }

  .jitsiInner {
    height: calc(100% - 22px);
  }
  
  .jitiLink {
    display: block;
    color: #ccc;
    text-align: center;
    margin-bottom: 10px;
    font-size: 12px;
    cursor: text;
  }

  > div.home > .dappopen {
    align-content: baseline;
  }

  > div.home > .dappopen > li {
    display: none;
  }

  > div.home > .dappopen > li.open {
    display: flex;
  }

  > div.home > .dappopen .dappopen {
    background: transparent !important;
    padding: 0;
    margin: 0;
  }

  > div.home > .dappopen .dappopen > span {
    display: none;
  }

  > div.home > .dappopen .dappopen li {
    display: none;
  }

  > div.home > .dappopen .dappopen li.open {
    display: flex;
  }

  .rooms > li {
    width: 85px;    
  }
  .rooms > li.open {
    width: 100%;    
  }

  .open.group > span, .open.group .open > span {
    max-width: 100%;
    line-height: 2;
  }

  .open.group {
    margin-top: -30px !important;
  }

  > div.home > h3 {
    margin-top: 20px;
    text-align: center;
    font-weight: 500;
    width: 100%;
  }

  h3.dappopen {
    display: none;
  }

}

@media only screen and (max-width: 480px), (max-height: 480px) {
  :local(.near-phone-popover) {
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    height: 100%;
    width: 100vw;
  }
}

:local(.tooltip) {
  position: relative;
  .tooltiptext {
    visibility: visible;
    width: 66px;
    background-color: #fff;
    color: #1b8a86;
    text-align: center;
    border-radius: 10px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 5px;
    right: -9px;
    font-size: 13px;
    opacity: 0.85;
    line-height: 1.2em;
  } 
  
}

:local(.fullscreen) {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  border-radius: 0;
  resize: none;
}