@use "../styles/theme.scss";

:local(.nearpulse) {

  max-width: 150px;

  animation: pulse 2s infinite linear;

  @keyframes pulse {
    0% {
      transform: scale3d(1, 1, 1);
    }
  
    50% {
      transform: scale3d(1.25, 1.25, 1.25);
    }
  
    100% {
      transform: scale3d(1, 1, 1);
    }
  }
}