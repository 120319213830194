@use "../styles/theme";

:local(.label) {
  margin-bottom: 8px;
  color: theme.$text2-color;
  align-self: flex-start;
  font-weight: theme.$font-weight-bold;
  font-size: theme.$font-size-sm
}

:local(.popover) {
  margin-bottom: -8px;
}