@use "../styles/theme.scss";

:local(.spinner) {
  animation: rotate 2s infinite linear;
  color: theme.$primary-color;

  @keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale3d(1, 1, 1);
    }
  
    50% {
      transform: scale3d(1.05, 1.05, 1.05);
    }
  
    100% {
      transform: scale3d(1, 1, 1);
    }
  }
}
