@use "../styles/theme.scss";


:local(.button-grid-popover) {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(48px, 1fr));
  gap: 16px;
  padding: 16px;

  &:not(:local(.fullscreen)) {
    max-width: 224px;
    padding-top: 0;
  }
}

