@use "../styles/theme.scss";

:local(.reaction-button) {
  display: flex;
  width: 100%;
  flex-direction: row;
  border-radius: 8px;
  border: 3px solid theme.$darkgrey;
  background-color: theme.$transparent;
  font-size: theme.$font-size-md;
  font-weight: theme.$font-weight-regular;
  align-items: center;
  padding: 6px 6px;

  & > * {
    margin-right: 0px;

    &:first-child {
      margin-right: 48px;
    }
  }

  &:hover {
    background-color: theme.$button-bg-color-hover;
    border: 3px solid theme.$transparent;
  }

  &:active {
    border: 3px solid theme.$darkgrey;
  }
}

:local(.active) {
  border: 3px solid theme.$active-color-pressed;
  background-color: theme.$button-bg-color-hover;

  &:hover {
    border: 3px solid theme.$active-color-pressed;
  }
}