@use "../styles/theme.scss";

:local(.sign-in-button) {
    display: none;
    @media(min-width: theme.$breakpoint-lg) {
        display: flex;
      }
}

:local(.mobile-sign-in) {
    display: flex;
    margin: -55px auto 0 auto;
    color: #fff;
    background-color: #00b5b0;
    font-size: 14px;
    @media(min-width: theme.$breakpoint-lg) {
        display: none;
      }
}