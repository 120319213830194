@use "../styles/theme.scss";

:local(.oauth-screen) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: theme.$background3-color;
  background-size: cover;
  pointer-events: all;
}