@use "../styles/theme.scss";

:local(.search-icon) {
  color: theme.$text1-color;
}

:local(.favorite-icon) {
  margin-right: 4px;
  color: theme.$favorite-color;
}

:local(.button-nav) {
  display: flex;
  padding: 8px;
  overflow-x: auto;
  flex-shrink: 0;
  border-bottom: 1px solid theme.$border1-color;
}

:local(.button-nav) > * {
  margin-right: theme.$spacing-sm;
}

:local(.button-nav) > :last-child {
  margin-right: 0;
}

:local(.facets-nav) {
  background-color: theme.$background2-color;
}

:local(.content) {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}

:local(.pager) {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

:local(.pager-button) {
  border: none;
  border-radius: 24px;
  background-color: theme.$primary-color;
  height: 48px;
  width: 48px;
  margin: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: theme.$text5-color;
  }

  &:hover {
    background-color: theme.$primary-color-hover;
  }

  &:active {
    background-color: theme.$primary-color-pressed;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: theme.$disabled-bg-color;

    svg {
      color: theme.$disabled-icon-color;
    }
  }
}

:local(.no-results) {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
