@use "../styles/theme.scss";

:local(.content) {
  align-items: center;
  padding: 24px;
  padding-top: 10px;
  text-align: center;
}

:local(.nameInput) {
  > div {
    background-color: transparent;    
    border: none;
  }
  > div input {
    text-align: center;
  }
}

:local(.avatar-preview-container) {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;

  /* TODO: This styling into AvatarPreview */
  & > :first-child {
    /*
    We need to set dimensions explicitly here, since AvatarPreview
    resizes itself to its container's dimensions.
    */
     width: 215px;
    height: 300px;
    border-radius: 8px;
    background-color: theme.$tile-bg-color;
  }

  button {
    position: absolute;
    bottom: 0;
    margin-bottom: 8px;
  }
}
